import logo from './MediumLogo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        
        

        
        <img src={logo} className="App-logo" alt="logo" />
        
        <div className="About">        
        Hello! We are an Arts & Crafts studio out of Wisconsin's Chain O' Lakes area. 
        Currently we are making sun catchers and jewelry using
        Lake Michigan rocks and beach glass. Feel free to reach out using the email below for custom orders, issues, or anything else!
        </div>
        <div className="Harvest">        
        We have a booth at the 715 Harvest Fest in King on October 14th. See you there!!!
        </div>
        <div className="Links">
          <div className="Fancy">morrisdecorativearts@gmail.com</div>
          <div className="Fancy">facebook.com/morrisdecorativearts</div>
          <div className="Fancy">instagram.com/morrisdecorativearts</div>          
        </div>

    </div>
  );
}

export default App;